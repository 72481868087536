<!--消费模块--结算规则设置-->
<template>
  <div
    id="settlementSettings"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("consume.a78") }}</my-headertitle>
    </div>
    <div class="main" ref="main" v-table-size:settlementSettings="tableSize">
      <my-tabletitle>{{ $t("consume.a79") }}</my-tabletitle>
      <a-table
        :columns="collectColumns"
        :data-source="collectData"
        :scroll="size"
        :pagination="pagination"
        bordered
        :rowClassName="CalssName"
        :rowKey="(record) => record.consumeReaderId"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <a @click="compile(record)">{{ $t("consume.a80") }}</a>
        </template>
      </a-table>
    </div>
    <a-modal
      :title="$t('consume.a81')"
      centered
      :width="1000"
      :visible="visible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.main"
      @cancel="Cancel"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("consume.a82") }}
        </a-button>
        <a-button key="submit" type="primary" @click="compileOk">
          {{ $t("consume.a83") }}
        </a-button>
      </template>
      <div v-loading="setloading" :element-loading-text="$t('consume.a1')">
        <my-tabletitle>{{ $t("consume.a84") }}</my-tabletitle>
        <a-table
          :columns="oneColumns"
          :data-source="oneData"
          :pagination="false"
          bordered
          :rowKey="(record) => record.consumeReaderId"
        >
          <template slot="mon" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.mon" />
          </template>
          <template slot="tue" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.tue" />
          </template>
          <template slot="wed" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.wed" />
          </template>
          <template slot="thurs" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.thurs" />
          </template>
          <template slot="friday" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.friday" />
          </template>
          <template slot="saturday" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.saturday" />
          </template>
          <template slot="sunday" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.sunday" />
          </template>
          <template slot="lunch" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.lunch" />
          </template>
          <template slot="supper" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.supper" />
          </template>
          <template slot="snack" slot-scope="text, record">
            <a-input placeholder="Basic usage" v-model="record.snack" />
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import tabletitle from "../../components/Title/tabletitle";
import global from "@/mixins/global.js";
import { getConsumePosRules, setConsumePosRules } from "../../api/consume";

export default {
  name: "settlementSettings",
  mixins: [global],
  data() {
    return {
      visible: false,
      loading: false,
      setloading: false,
      size: { y: 240 },
      collectData: [],
      collectColumns: [
        {
          title: "NO",
          dataIndex: "consumeDeviceIndex",
          align: "center",
          width: 60,
        },
        {
          title: this.$t("consume.a49"),
          dataIndex: "consumeReaderName",
          align: "center",
          width: 160,
          ellipsis: true,
        },
        {
          title: this.$t("consume.a85"),
          ellipsis: true,
          children: [
            {
              title: this.$t("consume.a17"),
              dataIndex: "mon",
              align: "center",
              ellipsis: true,
            },
            {
              title: this.$t("consume.a18"),
              dataIndex: "tue",
              align: "center",
              ellipsis: true,
            },
            {
              title: this.$t("consume.a19"),
              dataIndex: "wed",
              align: "center",
              ellipsis: true,
            },
            {
              title: this.$t("consume.a20"),
              dataIndex: "thurs",
              align: "center",
              ellipsis: true,
            },
            {
              title: this.$t("consume.a21"),
              dataIndex: "friday",
              align: "center",
              ellipsis: true,
            },
            {
              title: this.$t("consume.a22"),
              dataIndex: "saturday",
              align: "center",
              ellipsis: true,
            },
            {
              title: this.$t("consume.a23"),
              dataIndex: "sunday",
              align: "center",
              ellipsis: true,
            },
          ],
        },
        {
          title: this.$t("consume.a86"),
          dataIndex: "lunch",
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("consume.a87"),
          dataIndex: "supper",
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("consume.a88"),
          dataIndex: "snack",
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("consume.a89"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          align: "center",
        },
      ],
      oneData: [],
      oneColumns: [
        {
          title: this.$t("consume.a49"),
          dataIndex: "consumeReaderName",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("consume.a85"),
          children: [
            {
              title: this.$t("consume.a17"),
              dataIndex: "mon",
              scopedSlots: { customRender: "mon" },
              align: "center",
            },
            {
              title: this.$t("consume.a18"),
              dataIndex: "tue",
              scopedSlots: { customRender: "tue" },
              align: "center",
            },
            {
              title: this.$t("consume.a19"),
              dataIndex: "wed",
              scopedSlots: { customRender: "wed" },
              align: "center",
            },
            {
              title: this.$t("consume.a20"),
              dataIndex: "thurs",
              scopedSlots: { customRender: "thurs" },
              align: "center",
            },
            {
              title: this.$t("consume.a21"),
              dataIndex: "friday",
              scopedSlots: { customRender: "friday" },
              align: "center",
            },
            {
              title: this.$t("consume.a22"),
              dataIndex: "saturday",
              scopedSlots: { customRender: "saturday" },
              align: "center",
            },
            {
              title: this.$t("consume.a23"),
              dataIndex: "sunday",
              scopedSlots: { customRender: "sunday" },
              align: "center",
            },
          ],
        },
        {
          title: this.$t("consume.a86"),
          dataIndex: "lunch",
          scopedSlots: { customRender: "lunch" },
          align: "center",
        },
        {
          title: this.$t("consume.a87"),
          dataIndex: "supper",
          scopedSlots: { customRender: "supper" },
          align: "center",
        },
        {
          title: this.$t("consume.a88"),
          dataIndex: "snack",
          scopedSlots: { customRender: "snack" },
          align: "center",
        },
      ],
    };
  },
  mounted() {
    // 页面加载时获取页面数据
    this.loading = true;
    let data = {
      siteId: this.$route.query.id,
    };
    getConsumePosRules(data)
      .then((res) => {
        this.loading = false;
        const { data } = res;
        for (let i = 0; i < data.length; i++) {
          let rulelist = data[i].consumeDinnerRuleList;
          if (rulelist.length == 0) {
            data[i].mon = "-";
            data[i].tue = "-";
            data[i].wed = "-";
            data[i].thurs = "-";
            data[i].friday = "-";
            data[i].saturday = "-";
            data[i].sunday = "-";
            data[i].lunch = "-";
            data[i].supper = "-";
            data[i].snack = "-";
          } else {
            for (let j = 0; j < rulelist.length; j++) {
              let dinnerType = rulelist[j].dinnerType;
              switch (dinnerType) {
                case 1:
                  let setList = rulelist[j].consumeTimesSetList;
                  for (let k = 0; k < setList.length; k++) {
                    let wedDat = setList[k].wedDat;
                    switch (wedDat) {
                      case 1:
                        data[i].mon = setList[k].priceMoney;
                        break;
                      case 2:
                        data[i].tue = setList[k].priceMoney;
                        break;
                      case 3:
                        data[i].wed = setList[k].priceMoney;
                        break;
                      case 4:
                        data[i].thurs = setList[k].priceMoney;
                        break;
                      case 5:
                        data[i].friday = setList[k].priceMoney;
                        break;
                      case 6:
                        data[i].saturday = setList[k].priceMoney;
                        break;
                      case 7:
                        data[i].sunday = setList[k].priceMoney;
                        break;
                    }
                  }
                  break;
                case 2:
                  data[i].lunch = rulelist[j].consumeTimesSetList[0].priceMoney;
                  break;
                case 3:
                  data[i].supper =
                    rulelist[j].consumeTimesSetList[0].priceMoney;
                  break;
                case 4:
                  data[i].snack = rulelist[j].consumeTimesSetList[0].priceMoney;
                  break;
              }
            }
          }
        }
        this.collectData = data;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    // 编辑按钮
    compile(value) {
      this.visible = true;
      this.oneData = [value];
    },
    // 价格设置的确定按钮
    compileOk() {
      this.setloading = true;
      let list = this.oneData[0];
      let body = {};
      body.consumeDeviceIndex = list.consumeDeviceIndex;
      body.consumeReaderId = list.consumeReaderId;
      body.consumeReaderName = list.consumeReaderName;
      let consumeDinnerRuleList = [
        {
          dinnerType: 1,
          consumeTimesSetList: [
            { wedDat: 1, priceMoney: list.mon },
            { wedDat: 2, priceMoney: list.tue },
            { wedDat: 3, priceMoney: list.wed },
            { wedDat: 4, priceMoney: list.thurs },
            { wedDat: 5, priceMoney: list.friday },
            { wedDat: 6, priceMoney: list.saturday },
            { wedDat: 7, priceMoney: list.sunday },
          ],
        },
        {
          dinnerType: 2,
          consumeTimesSetList: [{ wedDat: 1, priceMoney: list.lunch }],
        },
        {
          dinnerType: 3,
          consumeTimesSetList: [{ wedDat: 1, priceMoney: list.supper }],
        },
        {
          dinnerType: 4,
          consumeTimesSetList: [{ wedDat: 1, priceMoney: list.snack }],
        },
      ];
      body.consumeDinnerRuleList = consumeDinnerRuleList;
      let query = {
        siteId: this.$route.query.id,
      };
      setConsumePosRules(body, query)
        .then((res) => {
          this.setloading = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
            list.mon = "-";
            list.friday = "-";
            list.lunch = "-";
            list.saturday = "-";
            list.snack = "-";
            list.sunday = "-";
            list.supper = "-";
            list.thurs = "-";
            list.tue = "-";
            list.wed = "-";
          }
        })
        .catch((err) => {
          console.log(err);
          this.setloading = false;
        });
    },
    // 价格设置弹窗关闭按钮
    Cancel() {
      this.visible = false;
    },
    // 表格隔行变色
    CalssName(record, index) {
      if (index % 2 === 1) {
        return "dark";
      }
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#settlementSettings {
  height: 100%;
  padding: 0px 20px;
}
.header {
  height: 51px;
}
.main {
  height: calc(100% - 51px);
}
div /deep/.dark {
  background-color: #eff8fb;
}
</style>